import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../styles.css";
import { useNavigate } from "react-router-dom";

const NewUserSignIn = () => {
  console.log("In NewUserSignIn");
  const auth = useAuth();
  const [cellphone, setCellPhone] = useState("");
  const [authtoken, setAuthToken] = useState("");
  const [message, setMessage] = useState("");
  const [codesent, setCodesent] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();

    console.log("Cellphone=", cellphone);

    const signIn = await auth.login(cellphone);

    if (signIn.error) {
      setMessage(signIn.error.message);
    } else {
      setMessage("Login link has been sent.");
      setCodesent(!codesent);
    }
  };

  const handleVerifyAccount = async (e) => {
    e.preventDefault();
    const verifyAccount = await auth.verify(cellphone, authtoken, "sms");

    console.log("Start 3");
    if (verifyAccount.error) {
      setMessage(verifyAccount.error.message);
    } else {
      setMessage("Redirecting...");
    }
    navigate("/determinesettings");
  };

  return (
    <div className="form-container">
      <Helmet>
        <title>New User Setup for Global Entry Notifications</title>
        <meta
          name="description"
          content="Sign into the Globe Entry Notification System."
        />
      </Helmet>
      <div className="location-preview">
        <h1>Welcome to New User Sign-Up</h1>
        <p>
          <label>
            <br />
            <br />
            <br />
            <br />
            <b>Step 1:</b>
            <br />
            <br />
            <br /> Since we will send you SMS/text alerts to notify you of
            upcoming appointment openings, let's verify your cell phone number.
            Please make sure that the country code on the left is correct, then
            enter your area code and cell number in the text box.
            <br />
          </label>
        </p>
        <form className="form-field" onSubmit={handleSignIn}>
          <PhoneInput
            className="inputbox"
            defaultCountry="US"
            value={cellphone}
            onChange={(cellphone) => setCellPhone(cellphone)}
          />
          <b>{message && message} </b>
          <p>
            <br />
            <label>
              Verify your cell number is correct, then press the{" "}
              <b>"Get Code"</b> button below to recieve you authorization code:
              <br />
            </label>
          </p>

          <button className="checkout-button" type={"submit"}>
            Get Code
          </button>
        </form>
        <br /> <br />
        {codesent ? (
          <>
            <p>
              <label>
                Type the 6 digit authorization code below.
                <br />
              </label>
            </p>

            <form className="form-field" onSubmit={handleVerifyAccount}>
              <input
                className="inputbox"
                type="authtoken"
                value={authtoken}
                onChange={(e) => setAuthToken(e.target.value)}
              />
              <br />
              <button className="checkout-button" type={"submit"}>
                Verify Authorization Code
              </button>
            </form>
            <br />
            <br />
            <br />
            <b>Thank you for choosing GlobalEntryNotifications.com. </b>
          </>
        ) : (
          <br />
        )}
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default NewUserSignIn;
