import React from "react";
import { Helmet } from "react-helmet-async";
import TextImage from "../images/GENText.jpeg";
import { useNavigate } from "react-router-dom";
import "../styles.css";
import "../Button.css";
// import SupabaseDateConverter from "../components/SupabaseDateConverter";

const SkipTheWait = () => {
  const navigate = useNavigate();

  return (
    <div className="form-container">
      <div className="herolocation-preview">
        <h1>
          Here are some FAQ on how our service helps you skip the wait for a
          Global Entry Interview!
        </h1>{" "}
        <Helmet>
          <title>How to get a Global Entry Interview quickly</title>
          <meta
            name="description"
            content="You can skip the line and score a quick Global Entry Interview. Here's how..."
          />
          <link
            rel="canonical"
            href="https://globalentrynotifications.com/SkipTheWait"
          />
        </Helmet>
        <div className="location-preview">
          <br />
          <b>Can you get an interview sooner?</b>
          <ul>
            {" "}
            Yes. Appointments become available quite often. This is mainly due
            to people rescheduling appointments and changes in employee work
            schedules.
          </ul>{" "}
          <b>Can I get notified when appointments become available?</b>
          <ul>
            Yes, our Global Entry Notifications system can send you a text as
            soon as an opening becomes available.
          </ul>
          <b>What do I do when I receive the text message?</b>
          <ul>
            The text message contains a link that will take you to the Homeland
            Security Truster Traveler site. You need to quickly log on, and book
            the available appointment.
          </ul>{" "}
          <b>How much does this cost?</b>{" "}
          <ul>
            The cost is a one-time fee of $20 for 35 days of access. It is not a
            subscription, and we are extremely confident you will book an
            appointmnent within that time period. As a matter of fact, we
            garantee your will find an appointment or we will give you a full
            refund.
          </ul>{" "}
          <b>How do you know when an appointment becomes available?</b>{" "}
          <ul>
            We constantly monitor the appointment openings, and will instantly
            send you a text when an opening meets your criteria.
          </ul>{" "}
          <b>Do I need to give you my Trusted Traveler ID and password?</b>{" "}
          <ul>
            <b>NO!</b> We tell you about the opening and you book it. This is
            very secure, we don't have any of your personal data, accept, of
            course, your cell phone number.
          </ul>
          <br />
          <table className="table-container">
            <tbody>
              <tr>
                <td className="table-cell-center-images">
                  <img
                    className="location-preview-img"
                    src={TextImage}
                    alt="Text Message"
                    width="125px"
                    height="100px"
                    loading="eager"
                  />
                </td>
                <td>
                  This is a screen shot of an actual text message, which
                  includes the link to log in to the goverment's TTS site:
                </td>
              </tr>
            </tbody>
          </table>
          <br /> <br />
          <button
            className="checkout-button"
            // buttonStyle="btn--outline"
            // buttonSize="btn--large"
            onClick={() => {
              navigate("/DetermineSettings");
            }}
          >
            I would like to try Global Entry Notifications
          </button>
          <br /> <br />
        </div>
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default SkipTheWait;
