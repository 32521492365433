import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../styles.css";
import "../Button.css";

const EditSettings = () => {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [location_ids, setLocation_ids] = useState([]);
  const [location_ids_original, setLocation_ids_alt] = useState([]);
  const [available_sunday, setAvailable_sunday] = useState("");
  const [available_monday, setAvailable_monday] = useState("");
  const [available_tuesday, setAvailable_tuesday] = useState("");
  const [available_wednesday, setAvailable_wednesday] = useState("");
  const [available_thursday, setAvailable_thursday] = useState("");
  const [available_friday, setAvailable_friday] = useState("");
  const [available_saturday, setAvailable_saturday] = useState("");
  const [current_appointment_date, setCurrent_appointment_date] =
    useState(null);
  const [errors, setErrors] = useState({});
  const [locations, setLocations] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(true);
  const [goodRead, setGoodRead] = useState(false);
  const defaultDate = new Date();

  const [dateStart, setDateStart] = useState(defaultDate);
  // const [current_appointment_date, setCurrent_appointment_date] =
  //   useState(defaultDate);

  //const [loading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [originalLocations, setOriginalLocations] = useState([]);
  //const regex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
  const [keepMyLocations, setKeepMyLocations] = useState(false);
  // const [userAppOptions, setUserAppOptions] = useState({
  //   id: "",
  //   first_name: "",
  //   last_name: "",
  //   location_ids: "",
  //   available_sunday: true,
  //   available_monday: true,
  //   available_tuesday: true,
  //   available_wednesday: true,
  //   available_thursday: true,
  //   available_friday: true,
  //   available_saturday: true,
  //   current_appointment_date: "",
  //   subscription_paid: false,
  //   subscription_end_date: "",
  //   aggreedSMSRule: false,
  // });

  function formatDate(datestring) {
    if (datestring == null) {
      const initial_Date = new Date();
      initial_Date.setDate(initial_Date.getDate() + 35);
      setCurrent_appointment_date(initial_Date);
    } else {
      const assigned_Date = new Date(datestring);
      assigned_Date.setDate(assigned_Date.getDate() + 1);
      setCurrent_appointment_date(assigned_Date);
    }

    // const temp = datestring.replace("-", "/");
    // const currentappdate = new Date(datestring.replace("-", "/"));
    // console.log("formatDate=", currentappdate);
    return;
  }

  const handleDisclaimerChange = () => {
    if (!keepMyLocations) {
      setLocation_ids(location_ids_original);
    } else {
      setLocation_ids([]);
    }
    setKeepMyLocations(!keepMyLocations);
  };

  // function formatPhoneNumber(phoneCountryCodeString, phoneNumberString) {
  //   var cleaned = ("" + phoneCountryCodeString + phoneNumberString).replace(
  //     /\D/g,
  //     ""
  //   );
  //   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     var intlCode = match[1] ? "+1 " : "";
  //     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  //   }
  //   return null;
  // }

  const getSettings = async () => {
    console.log("Settings id=", auth.user.id);
    const { error, data } = await supabase
      .from("preferences")
      .select("*")
      .eq("id", auth.user.id);
    if (error) throw error;
    if (data !== null) {
      console.log(data);
      //setSettings(data);
      setFirst_name(data[0].first_name);
      setLast_name(data[0].last_name);
      setLocation_ids(data[0].location_ids);
      setLocation_ids_alt(data[0].location_ids);
      setOriginalLocations(data[0].location_ids);
      setAvailable_sunday(data[0].available_sunday);
      setAvailable_monday(data[0].available_monday);
      setAvailable_tuesday(data[0].available_tuesday);
      setAvailable_wednesday(data[0].available_wednesday);
      setAvailable_thursday(data[0].available_thursday);
      setAvailable_friday(data[0].available_friday);
      setAvailable_saturday(data[0].available_saturday);
      formatDate(data[0].current_appointment_date);

      setIsPending(false);
      setGoodRead(true);
      //setCurrent_appointment_date(data[0].current_appointment_date);
      //supabase data element needs to be formatted with '/' not '-' or you will lose a day.
      // let tmpday = new Date(data[0].current_appointment_date.replace("-", "/"));
      // setDateStart(tmpday);
      // setSubscription_paid(data[0].subscription_paid);
      // setSubscription_end_date(data[0].subscription_end_date);
    }
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = async () => {
    const { data, error } = await supabase
      .from("locations")
      .select("id, name, city, state")
      .neq("address", "Permanently Closed")
      .order("countryCode", { ascending: false })
      .order("state", { ascending: true });
    if (error) throw error;
    if (data !== null) {
      console.log("locations=", data);
      setLocations(data);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const locationoptions = locations.map((d, index) => ({
    value: d.id,
    label: d.state + ", " + d.city + ": " + d.name,
  }));

  const temparray = location_ids
    .toString()
    .replace("[", "")
    .replace("]", "")
    .split(",");

  const handleLocationChange = (e) => {
    console.log("handleLocationChange e", e);
    setLocation_ids(Array.isArray(e) ? e.map((x) => x.value) : []);
    console.log("location_ids=", location_ids);
    //console.log(selectedLocations);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const formErrors = validateForm();
  //   if (Object.keys(formErrors).length > 0) {
  //     setErrors(formErrors);
  //   } else {
  //     handleClicksave();
  //   }
  // };

  const handleClicksave = async (e) => {
    e.preventDefault();
    console.log("handleSave=", selectedLocations);
    let formattedLocations = JSON.stringify(selectedLocations);
    formattedLocations = formattedLocations.replaceAll("[", "");
    formattedLocations = formattedLocations.replaceAll("]", "");

    setLocation_ids(formattedLocations);

    console.log("Save  current_app_date=", dateStart);

    const { data, error } = await supabase
      .from("preferences")
      .upsert({
        id: auth.user.id,
        first_name: first_name,
        last_name: last_name,
        location_ids: location_ids,
        available_sunday: available_sunday,
        available_monday: available_monday,
        available_tuesday: available_tuesday,
        available_wednesday: available_wednesday,
        available_thursday: available_thursday,
        available_friday: available_friday,
        available_saturday: available_saturday,
        current_appointment_date:
          current_appointment_date.toLocaleDateString("en-US"),
        settings_updated_at: new Date().toISOString().toLocaleString("zh-TW"),
        // nativeDate.toLocaleDateString("en-US", {
        //   year: "numeric",
        //   month: "2-digit",
        //   day: "2-digit",
        // })
      })
      .select();

    console.log("upsert completed");

    if (error) {
      console.log("Please fill in all the fields correctly.", error);
    }
    // if (data) {
    //   navigate("/");
    // }
    if (data) {
      console.log("In Edit Settings: data=", data);
      let today = new Date();
      let tmpday;
      if (data[0].subscription_end_date)
        tmpday = new Date(data[0].subscription_end_date.replace("-", "/"));
      if (data[0].subscription_paid !== true) {
        navigate("/Checkout");
      } else if (data[0].subscription_end_date && today > tmpday) {
        navigate("/Checkout");
      } else {
        navigate("/");
      }
    }
    //updatePreferences();
  };

  function onChangeDateHandler(value) {
    //setDateStart(value);
    setCurrent_appointment_date(value);
    //setCurrent_appointment_date(value);
    console.log("onChangeDateHandler value=", dateStart);
  }

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  const location1 = 6940;
  let index = -1;

  if (locationoptions) {
    index = getIndex(location1, locationoptions, "value");
  }

  const validateForm = () => {
    const newErrors = {};
    const todaysDate = new Date();

    if (!first_name || first_name === "")
      newErrors.first_name = "First name cannot be blank.";
    //else setFirst_name(first_name);

    if (!last_name || last_name === "")
      newErrors.last_name = "Last name cannot be blank.";
    //else setLast_name(last_name);

    if (!current_appointment_date || current_appointment_date === "")
      newErrors.appointmentDate = "Date cannot be blank.";
    else if (current_appointment_date < todaysDate)
      newErrors.appointmentDate = "Date must be greater than today.";
    if (!location_ids || location_ids.length === 0)
      newErrors.locationSelect = "Select at least 1 location.";
    else if (location_ids.length > 3)
      newErrors.locationSelect = "Select no more than 3 locations.";
    if (
      !(
        available_sunday ||
        available_monday ||
        available_tuesday ||
        available_wednesday ||
        available_thursday ||
        available_friday ||
        available_saturday
      )
    ) {
      newErrors.checkboxdays = "You must check at least one day.";
    }

    return newErrors;
  };

  const {
    register,
    // handleSubmit,
    //formState: { errors },
  } = useForm();

  return (
    <div className="form-container">
      <Helmet>
        <title>Edit User Settings for Global Entry Notifications</title>
        <meta
          name="description"
          content="Edit your criteria to determine when to be texted about newly available Global Entry Interview."
        />
      </Helmet>
      {isPending && <div>Loading...</div>}
      {goodRead && (
        <div className="location-preview">
          <h1>User Settings</h1>
          <form onSubmit={handleClicksave}>
            <Form.Group className="form-field" controlId="first_name">
              <Form.Label>
                <b>First Name:</b>
              </Form.Label>

              <Form.Control
                className="inputbox"
                type="text"
                {...register("first_name", {
                  required: "Please enter your First Name",
                  pattern: {
                    value: "^[a-zA-Z ]*$",
                    message: "Please enter a First Name",
                  },
                })}
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
              />
              {errors.first_name && (
                <p className="errorMsg">{errors.first_name.message}</p>
              )}
            </Form.Group>

            <Form.Group className="form-field" controlId="last-name">
              <Form.Label>
                <b>Last Name:</b>
              </Form.Label>

              <Form.Control
                className="inputbox"
                type="text"
                {...register("last_name", {
                  required: "Please enter your Last Name",
                  pattern: {
                    value: "^[a-zA-Z ]*$",
                    message: "Please enter a Last Name",
                  },
                })}
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
              />
              {errors.last_name && (
                <p className="errorMsg">{errors.last_name.message}</p>
              )}
            </Form.Group>

            <Form.Group
              className="inputlabel"
              controlId="current_appointment_date"
            >
              <Form.Label>
                <b>Only notify me of appointments before this date:</b>
              </Form.Label>
              {/* add inline style to className */}
              <span className="inputbox">
                <DatePicker
                  id="dateStartEnd"
                  selected={current_appointment_date}
                  onChange={onChangeDateHandler}
                  dateFormat="MM/dd/yyyy"
                  className={"inputbox"}
                  showDisabledMonthNavigation
                />
              </span>

              {/* <Form.Control
              className="inputbox"
              type="text"
              placeholder={maxDate}
              {...register("current_appointment_date", {
                required: "Enter a valid Date",
                pattern: {
                  value: regex,
                  message: "Please enter a valid date",
                },
              })}
              value={current_appointment_date}
              //value={formatDate("2023-02-22")}
              onChange={(e) => setCurrent_appointment_date(e.target.value)}
            />
            {errors.current_appointment_date && (
              <p className="errorMsg">
                {errors.current_appointment_date.message}
              </p>
            )} */}
            </Form.Group>

            <Form.Group className="form-field" controlId="getlocations">
              <Form.Label>
                <label>
                  <input
                    //className="checkbox"
                    type="checkbox"
                    checked={keepMyLocations}
                    onChange={handleDisclaimerChange}
                  />{" "}
                  <b>
                    Check here to keep your currently saved location
                    selection(s):
                  </b>
                </label>
                {}
                <span className="blue-text">
                  {temparray[0] ? (
                    locationoptions
                      .filter(
                        (locationoption) =>
                          locationoption.value.toString() === temparray[0]
                      )
                      .map((desiredlocation1) => (
                        <li key={temparray[0]}>{desiredlocation1.label}</li>
                      ))
                  ) : (
                    <br />
                  )}
                  {temparray[1]
                    ? locationoptions
                        .filter(
                          (locationoption) =>
                            locationoption.value.toString() === temparray[1]
                        )
                        .map((desiredlocation1) => (
                          <li key={temparray[1]}>{desiredlocation1.label}</li>
                        ))
                    : ""}
                  {temparray[2]
                    ? locationoptions
                        .filter(
                          (locationoption) =>
                            locationoption.value.toString() === temparray[2]
                        )
                        .map((desiredlocation1) => (
                          <li key={temparray[2]}>{desiredlocation1.label}</li>
                        ))
                    : " "}
                </span>
              </Form.Label>

              {!keepMyLocations ? (
                <span className="form-field">
                  <br />
                  <Form.Label>
                    <b>Please select up to 3 desired interview locations:</b>
                  </Form.Label>

                  <Select
                    className="inputbox"
                    placeholder="Select Option"
                    //defaultValue={[locationoptions[index]]}
                    options={locationoptions} // set list of the data
                    onChange={handleLocationChange} // assign onChange function
                    isMulti
                    isClearable
                    //isOptionDisabled={() => location_ids.length >= 3}
                  />
                </span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group className="form-field" controlId="appointmentdays">
              <Form.Label>
                <b>Only notify me of appointments on these days of the week:</b>
              </Form.Label>
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Sunday"
                checked={available_sunday}
                {...register("appointmentdays", {
                  required: "Please select at-least one day",
                })}
                onChange={(e) => setAvailable_sunday(!available_sunday)}
              />
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Monday"
                checked={available_monday}
                {...register("appointmentdays", {
                  required: "Please select at-least one day",
                })}
                value={available_monday}
                onChange={(e) => setAvailable_monday(!available_monday)}
              />
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Tuesday"
                checked={available_tuesday}
                {...register("appointmentdays")}
                value={available_tuesday}
                onChange={(e) => setAvailable_tuesday(!available_tuesday)}
              />
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Wednesday"
                //defaultValue={setAvailable_wednesday}
                checked={available_wednesday}
                {...register("appointmentdays")}
                value={available_wednesday}
                onChange={(e) => setAvailable_wednesday(!available_wednesday)}
              />
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Thursday"
                checked={available_thursday}
                {...register("appointmentdays")}
                value={available_thursday}
                onChange={(e) => setAvailable_thursday(!available_thursday)}
              />
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Friday"
                checked={available_friday}
                {...register("appointmentdays")}
                value={available_friday}
                onChange={(e) => setAvailable_friday(!available_friday)}
              />
              <Form.Check
                className="checkbox"
                type="checkbox"
                label="Saturday"
                checked={available_saturday}
                {...register("appointmentdays")}
                value={available_saturday}
                onChange={(e) => setAvailable_saturday(!available_saturday)}
              />
              {errors.appointmentdays && (
                <p className="errorMsg">{errors.appointmentdays.message}</p>
              )}
              <br />
              <br />
              <span style={{ marginLeft: "2rem" }}>
                <button className="checkout-button">Save</button>
              </span>
              <br />
              <br />
              <span style={{ marginLeft: "2rem" }}>
                <button
                  className="checkout-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </button>
              </span>
            </Form.Group>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditSettings;
