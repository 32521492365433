import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../styles.css";

function Cancel() {
  const navigate = useNavigate();
  return (
    <div className="form-container">
      <Helmet>
        <title>Cancel or Change your Global Entry Purchase</title>
        <meta
          name="description"
          content="Cancel or Change your Global Entry Purchase."
        />
      </Helmet>
      <div className="location-preview">
        <h1>Unfortunatly your payment was cancelled.</h1>
        <p>
          If you did not mean to cancel your payment, please try again.
          <br />
          If you need assistance, please email us at
          support@globalentrynotifications.com
        </p>
        <br />
        <br />
        <button
          className="checkout-button"
          onClick={() => {
            navigate("/checkout");
          }}
        >
          Return to Checkout
        </button>
        <br />
        <br />
        <br />
        <button
          className="checkout-button"
          onClick={() => {
            navigate("/");
          }}
        >
          Return to Home
        </button>
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
}

export default Cancel;
