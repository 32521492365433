import React from "react";
import "../App.css";
import { Helmet } from "react-helmet-async";
import SignOutForm from "./SignOutForm";

export default function SignOut() {
  console.log("SignOut.js");
  return (
    <div>
      <Helmet>
        <title>User logoff for Global Entry Notifications</title>
        <meta
          name="description"
          content="Sign out of the Globe Entry Notifications System."
        />
      </Helmet>
      <SignOutForm />
    </div>
  );
}
