import React, { useState, useEffect } from "react";
// import Navbar from "../components/Navbar";
// import Select from "react-select";
// import { Form } from "react-bootstrap";
import { useAuth } from "../auth";
import { supabase } from "../supabase";
import { useNavigate } from "react-router-dom";
import "../styles.css";
import "../Button.css";
// import SupabaseDateConverter from "../components/SupabaseDateConverter";

const ReadOnlySettings = () => {
  //alert("ReadOnlySettings");
  // const [settings, setSettings] = useState([]);
  const [first_name, setFirst_name] = useState([]);
  const [last_name, setLast_name] = useState([]);
  const [location_ids, setLocation_ids] = useState([]);
  const [available_sunday, setAvailable_sunday] = useState([]);
  const [available_monday, setAvailable_monday] = useState([]);
  const [available_tuesday, setAvailable_tuesday] = useState([]);
  const [available_wednesday, setAvailable_wednesday] = useState([]);
  const [available_thursday, setAvailable_thursday] = useState([]);
  const [available_friday, setAvailable_friday] = useState([]);
  const [available_saturday, setAvailable_saturday] = useState([]);
  const [current_appointment_date, setCurrent_appointment_date] =
    useState(null);
  // const [subscription_paid, setSubscription_paid] = useState([]);
  const [subscription_end_date, setSubscription_end_date] = useState([]);
  const [locations, setLocations] = useState([]);
  const auth = useAuth();
  const [isPending, setIsPending] = useState(true);
  // const [selectedLocations, setSelectedLocations] = useState([]);
  // const regex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
  const navigate = useNavigate();
  const [goodRead, setGoodRead] = useState(false);
  // const [userAppOptions, setUserAppOptions] = useState({
  //   id: "",
  //   first_name: "",
  //   last_name: "",
  //   location_ids: "",
  //   available_sunday: true,
  //   available_monday: true,
  //   available_tuesday: true,
  //   available_wednesday: true,
  //   available_thursday: true,
  //   available_friday: true,
  //   available_saturday: true,
  //   current_appointment_date: "",
  //   subscription_paid: false,
  //   subscription_end_date: "",
  //   aggreedSMSRule: false,
  // });

  function formatAppointmentDate(datestring) {
    console.log("start formatAppointmentDate:", datestring);
    if (datestring == null) {
      setCurrent_appointment_date(null);
    } else {
      const appointment_Date = new Date(datestring);
      appointment_Date.setDate(appointment_Date.getDate() + 1);
      setCurrent_appointment_date(appointment_Date);
    }
    console.log("end formatAppointmentDate:", current_appointment_date);
  }

  function formatSubscriptionDate(datestring) {
    console.log("start formatSubscriptionDate:", datestring);
    if (datestring == null) {
      setSubscription_end_date(null);
    } else {
      const subscription_Date = new Date(datestring);
      subscription_Date.setDate(subscription_Date.getDate() + 1);
      setSubscription_end_date(subscription_Date);
    }
    console.log("end formatSubscriptionDate:", subscription_end_date);
  }

  const getSettings = async () => {
    console.log("Settings id=", auth.user.id);
    setIsPending(true);
    // // alert("Start getSettings");
    const { error, data } = await supabase
      .from("preferences")
      .select("*")
      .eq("id", auth.user.id);

    console.log("getSettingsCall=", data);

    if (error) {
      // // alert("Error getSettings");
      console.log("Please fill in all the fields correctly. Error=", error);
    }
    if (data.length === 0) {
      // // alert("Length is zero getSettings");
      navigate("/createsettings");
    }
    if (data) {
      console.log("Settings data=", data);
      // // alert("Data Found getSettings");
      // setSettings(data);
      setFirst_name(data[0].first_name);
      setLast_name(data[0].last_name);
      setLocation_ids(data[0].location_ids);
      setAvailable_sunday(data[0].available_sunday);
      setAvailable_monday(data[0].available_monday);
      setAvailable_tuesday(data[0].available_tuesday);
      setAvailable_wednesday(data[0].available_wednesday);
      setAvailable_thursday(data[0].available_thursday);
      setAvailable_friday(data[0].available_friday);
      setAvailable_saturday(data[0].available_saturday);

      formatAppointmentDate(data[0].current_appointment_date);
      formatSubscriptionDate(data[0].subscription_end_date);
      //setSubscription_paid(data[0].subscription_paid);
      //alert("Data Found getSettings -mid10");
      setIsPending(false);
      // // alert("Data Found getSettings -mid11");
      setGoodRead(true);
      // // alert("Data Found getSettings -end");
    }
    // // alert("Finish getSettings");
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = async () => {
    // // alert("Start getLocations");
    const { data, error } = await supabase
      .from("locations")
      .select("id, name, city, state")
      .neq("address", "Permanently Closed")
      .order("countryCode", { ascending: false })
      .order("state", { ascending: true });
    if (error) throw error;
    if (data != null) {
      console.log("locations=", data);
      setLocations(data);
    }
    // // alert("Finish getLocations");
  };

  useEffect(() => {
    getLocations();
  }, []);

  const locationoptions = locations.map((d, index) => ({
    value: d.id,
    label: d.state + ", " + d.city + ": " + d.name,
  }));

  const temparray = location_ids
    .toString()
    .replace("[", "")
    .replace("]", "")
    .split(",");

  // const handleLocationChange = (e) => {
  //   console.log("handleLocationChange e", e);
  //   setLocation_ids(Array.isArray(e) ? e.map((x) => x.value) : []);
  //   console.log("location_ids=", location_ids);
  //   //console.log(selectedLocations);
  // };

  let today = new Date();
  today.setDate(today.getDate() + 35);
  // let maxDate = new Date(today).toLocaleDateString("us");

  // function getIndex(value, arr, prop) {
  //   for (var i = 0; i < arr.length; i++) {
  //     if (arr[i][prop] === value) {
  //       return i;
  //     }
  //   }
  //   return -1; //to handle the case where the value doesn't exist
  // }

  // const location1 = 6940;
  // let index = -1;

  // if (locationoptions) {
  //   index = getIndex(location1, locationoptions, "value");
  // }

  const handleClickGetStarted = () => navigate("/EditSettings");

  return (
    <div className="location-preview">
      {isPending && <div>Loading...</div>}
      {goodRead && (
        <>
          <h1>{first_name}'s Current User Settings</h1>
          <br />
          <b>Name:</b> {first_name} {last_name}
          <br />
          <br />
          <b>Desired Location(s): </b> <br />
          {}
          {temparray[0]
            ? locationoptions
                .filter(
                  (locationoption) =>
                    locationoption.value.toString() === temparray[0]
                )
                .map((desiredlocation1) => (
                  <li key={temparray[0]}>{desiredlocation1.label}</li>
                ))
            : ""}
          {temparray[1]
            ? locationoptions
                .filter(
                  (locationoption) =>
                    locationoption.value.toString() === temparray[1]
                )
                .map((desiredlocation1) => (
                  <li key={temparray[1]}>{desiredlocation1.label}</li>
                ))
            : ""}
          {temparray[2]
            ? locationoptions
                .filter(
                  (locationoption) =>
                    locationoption.value.toString() === temparray[2]
                )
                .map((desiredlocation1) => (
                  <li key={temparray[2]}>{desiredlocation1.label}</li>
                ))
            : ""}
          <br />
          <b>Weekday Availability:</b> <br />
          <li>
            <b>Sunday: </b>
            {"    "}
            {available_sunday ? "Available" : "Not Available"} <br />{" "}
          </li>
          <li>
            <b>Monday: </b>
            {"    "}
            {available_monday ? "Available" : "Not Available"} <br />
          </li>
          <li>
            <b>Tuesday: </b>
            {"    "}
            {available_tuesday ? "Available" : "Not Available"} <br />
          </li>
          <li>
            <b>Wednesday: </b>
            {"    "}
            {available_wednesday ? "Available" : "Not Available"} <br />
          </li>
          <li>
            <b>Thursday: </b>
            {"    "}
            {available_thursday ? "Available" : "Not Available"} <br />
          </li>
          <li>
            <b>Friday: </b>
            {"    "}
            {available_friday ? "Available" : "Not Available"} <br />
          </li>
          <li>
            <b>Saturday: </b>
            {"    "}
            {available_saturday ? "Available" : "Not Available"} <br />
          </li>
          <br /> <br />
          {current_appointment_date ? (
            <>
              <b>Only send notifications of appointments before this date: </b>{" "}
              {current_appointment_date.toLocaleDateString()}
              <br /> <br />
            </>
          ) : (
            <br />
          )}
          {subscription_end_date ? (
            <>
              <b>Your access will end on: </b>{" "}
              {subscription_end_date.toLocaleDateString()}
              <br /> <br />
            </>
          ) : (
            <br />
          )}
          {/* {subscription_end_date ? (
            <span>
              <b>Notifications will end on: </b> {subscription_end_date}
              <br /> <br />
            </span>
          ) : (
            <br />
          )} */}
          <br /> <br />
          <div className="form-field-left">
            <button
              className="checkout-button"
              // buttonStyle="btn--outline"
              // buttonSize="btn--large"
              onClick={handleClickGetStarted}
            >
              Change My Settings
            </button>
          </div>
          <br /> <br />
        </>
      )}
    </div>
  );
};

export default ReadOnlySettings;
