import React from "react";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import "../styles.css";

const SignOutForm = () => {
  const auth = useAuth();
  console.log("In signoutform.js Auth=", auth);

  return (
    <div className="form-container">
      <Helmet>
        <title>New User Sign-in for Global Entry Notifications</title>
        <meta
          name="description"
          content="Sign out of the Globe Entry Notifications System."
        />
      </Helmet>
      <div className="location-preview">
        {auth.user ? (
          <span>
            <h1>Log Off</h1>
            <form className="form-field">
              <button onClick={auth.logout}>Press to log off</button>
            </form>
          </span>
        ) : (
          <span>You have been Logged off!</span>
        )}
        <br /> <br />
        <Link to={`/`}>
          <h3>Back to Home Page</h3>
        </Link>
        <br /> <br />
      </div>
    </div>
  );
};

export default SignOutForm;
