import React, { useEffect } from "react";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./pages/Settings";
import ReadOnlySettings from "./pages/ReadOnlySettings";
import EditSettings from "./pages/EditSettings";
import Locations from "./pages/Locations";
import LocationDetails from "./pages/LocationDetails";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignOut from "./pages/SignOut";
import SkipTheWait from "./pages/SkipTheWait";
import Navbar from "./components/Navbar";
import CreateSettings from "./pages/CreateSettings";
import NewUserSignIn from "./pages/NewUserSignIn";
import Checkout from "./pages/Checkout";
import Cancel from "./pages/Cancel";
import BlogList from "./pages/BlogList";
import Blogs from "./pages/Blogs";
import Success from "./pages/Success";
import TestPage from "./pages/TestPage";
import TTPRedirect from "./pages/schedule";
import DetermineSettings from "./pages/DetermineSettings";
import GlobalEntryProcess from "./pages/GlobalEntryProcess.js";
import ShowMeMovie from "./pages/ShowMeMovie";
import GetAnAppointment from "./pages/GetAnAppointment";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TDGV4ML",
};
TagManager.initialize(tagManagerArgs);

function App() {
  console.log("App");

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: window.location.pathname,
      },
    });
  }, []);

  //Ensured chatbot code was removed

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path={"locations"} element={<Locations />} />
        <Route path={"signin"} element={<SignIn />} />
        <Route path={"signout"} element={<SignOut />} />
        <Route path={"newusersignin"} element={<NewUserSignIn />} />
        <Route path={"createsettings"} element={<CreateSettings />} />
        <Route path={"settings"} element={<Settings />} />
        <Route path={"schedule"} element={<TTPRedirect />} />
        <Route path={"TestPage"} element={<TestPage />} />
        <Route path={"cancel"} element={<Cancel />} />
        <Route path={"BlogList"} element={<BlogList />} />{" "}
        <Route path={"success"} element={<Success />} />
        <Route path={"globalentryprocess"} element={<GlobalEntryProcess />} />
        <Route path={"showmemovie"} element={<ShowMeMovie />} />
        <Route path={"SkipTheWait"} element={<SkipTheWait />} />
        <Route path={"getanappointment"} element={<GetAnAppointment />} />
        <Route
          path={"DetermineSettings"}
          element={
            <ProtectedRoute>
              <DetermineSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={"ReadOnlySettings"}
          element={
            <ProtectedRoute>
              <ReadOnlySettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={"EditSettings"}
          element={
            <ProtectedRoute>
              <EditSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={"checkout"}
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route path={"/locationdetails/:id"} element={<LocationDetails />} />
        <Route path={"/blogs/:id"} element={<Blogs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
