import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import GEProcess1 from "../images/Process1.jpeg";
import GEProcess2 from "../images/Process2.jpeg";
import GEProcess3 from "../images/Process3.jpeg";
import GEProcess4 from "../images/Process4.jpeg";
import GEProcess5 from "../images/Process5.jpeg";
import GEProcessFinal from "../images/ProcessFinal.jpeg";
import GEProcessThanks from "../images/ProcessThanks.jpeg";
import "../App.css";
import "../styles.css";

const GlobalEntryProcess = () => {
  console.log("Start: HeroSection.js");

  return (
    <div className="form-container">
      <Helmet>
        <title>The Global Entry Process</title>
        <meta
          name="description"
          content="Learn all the steps needed to obtain your Globe Entry Passport."
        />
        <link
          rel="canonical"
          href="https://globalentrynotifications.com/GlobalEntryProcess"
        />
      </Helmet>
      <div className="location-preview">
        <h1>The Global Entry Process:</h1> <br />
        <img
          className="btn--steps"
          src={GEProcess1}
          alt="The Global Entry Process"
          title="Create Trusted Traveler Account"
          // width="1000px"
          // height="330px"
          loading="eager"
        />
        <br />
        <img
          className="btn--steps"
          src={GEProcess2}
          alt="The Global Entry Process"
          title="Fill out Global Entry form"
          // width="1000px"
          // height="330px"
          loading="eager"
        />
        <br />
        <img
          className="btn--steps"
          src={GEProcess3}
          alt="The Global Entry Process"
          title="Schedule in-person Global Entry Interview"
          // width="1000px"
          // height="330px"
          loading="eager"
        />
        <br />
        <img
          className="btn--steps"
          src={GEProcess4}
          alt="The Global Entry Process"
          title="The Global Entry Process"
          // width="1000px"
          // height="330px"
          loading="eager"
        />
        <br />
        <img
          className="btn--steps"
          src={GEProcess5}
          alt="The Global Entry Process"
          title="Global Entry Notifications"
          // width="1000px"
          // height="330px"
          loading="eager"
        />
        <br />
        <img
          className="btn--steps"
          src={GEProcessFinal}
          alt="The Global Entry Process"
          title="React to a Global Entry Notification"
          // width="1000px"
          // height="550px"
          loading="eager"
        />
        <img
          className="btn--steps"
          src={GEProcessThanks}
          alt="The Global Entry Process"
          title="The Global Entry Process"
          // width="1000px"
          // height="400px"
          loading="eager"
        />
        <br />
        <br />
        <Link to={`/`}>
          <h3>Back to Home</h3>
        </Link>
        <br />
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default GlobalEntryProcess;
