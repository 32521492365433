import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./auth";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <AuthProvider>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </AuthProvider>,
  document.getElementById("root")
);
