import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Step1 from "./images/learnaboutGE.jpg";
import Step1a from "./images/locationlist.jpg";
import Step2 from "./images/signup.jpg";
import Step3 from "./images/gotnotified.jpg";
import Step4 from "./images/showmemovie.jpg";
import { supabase } from "./supabase";
import "./styles.css";
import "./HeroSection.css";
import "./Button.css";
import Popup from "./components/Popup";

function HeroSection() {
  console.log("Start: HeroSection.js");
  const [goodRead, setGoodRead] = useState(false);
  const [interviewLocation, setInterviewLocation] = useState("");
  const [textLine1, setTextLine1] = useState("");
  const [textLine2, setTextLine2] = useState("");
  const [textAve, setTextAve] = useState("");
  const [lineCount, setLineCount] = useState(0);
  const [count, setCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const defaultDate = new Date();
  // console.log("1Create startup default:", defaultDate);
  defaultDate.setDate(defaultDate.getDate() + 20);

  const navigate = useNavigate();

  const getLocations = async () => {
    //const { data, error } = await supabase.from("locations").select("*");
    const { data, error } = await supabase
      .from("locations")
      .select("*")
      .neq("address", "Permanently Closed")
      .not("avg_appointments_found", "is", null)
      .lt("avg_appointments_found", 40)
      .gte("earliest_appoinment_date", defaultDate.toDateString())
      .order("countryCode", { ascending: false })
      .order("state", { ascending: true }) //.order("countryCode", false)
      .order("city", { ascending: true }); //.order("countryCode", false)
    // .order("state", true);
    if (error) throw error;
    else if (data != null) {
      setLocations(data);
      setLineCount(data.length);
      setGoodRead(true);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  // setLineCount(3);
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      console.log("Count=", count);
      console.log("lineCount=", lineCount);
      if (lineCount !== 0) {
        if (count < lineCount) {
          setTextLine1(
            locations[count].city + ", " + locations[count].state + ": "
          );
          setTextAve(
            Math.round(
              (locations[count].avg_appointments_found + Number.EPSILON) * 10
            ) / 10
          );
          setTextLine2(" per day.");
          if (locations[count].shortName.length > 0) {
            setInterviewLocation("(" + locations[count].shortName + ")");
          } else {
            setInterviewLocation("");
          }
          setCount(count + 1);
        } else {
          setCount(0);
        }
      } else {
        setTextLine1("");
        setTextLine2("");
      }
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [count, lineCount, locations]);

  return (
    <div className="herolocation-preview">
      <h1>Get Global Entry Interview Appointments Quickly!</h1>
      {goodRead ? (
        <Popup
          trigger={true}
          descriptionLine1={textLine1}
          average={textAve}
          descriptionLine2={textLine2}
          enrollmentcenter={interviewLocation}
        />
      ) : (
        <br />
      )}
      <br />
      {/* <label>
        The ultimate solution for securing an interview in the Global Entry
        program in a matter of days instead of several months!
      </label> */}
      <button
        className="btn--steps"
        onClick={() => {
          navigate("/globalentryprocess");
        }}
      >
        <img
          className="btn--steps"
          src={Step1}
          alt="Explain the Global Entry Process"
          title="Explain the Global Entry Process"
          width="400px"
          height="141px"
          loading="eager"
        />
      </button>
      <br />
      <br />
      <button
        className="btn--steps"
        onClick={() => {
          navigate("/locations");
        }}
      >
        <img
          className="btn--steps"
          src={Step1a}
          alt="Current wait for Global Entry Interviews"
          title="Current wait for Global Entry Interviews"
          width="400px"
          height="141px"
          loading="eager"
        />
      </button>
      <br />
      <br />
      <button
        className="btn--steps"
        onClick={() => {
          navigate("/newusersignin");
        }}
      >
        <img
          className="btn--steps"
          src={Step2}
          alt="Sign-up for Global Entry Notifications and get an interview sooner"
          title="Sign-up for Global Entry Notifications and get an interview sooner"
          width="400px"
          height="141px"
          loading="eager"
        />
      </button>
      <br />
      <br />
      <button
        className="btn--steps"
        onClick={() => {
          navigate("/getanappointment");
        }}
      >
        <img
          className="btn--steps"
          src={Step3}
          alt="Know what to do when you get a notification"
          title="What to do when you get a notification"
          width="400px"
          height="141px"
          loading="eager"
        />
      </button>
      <br />
      <br />
      <button
        className="btn--steps"
        onClick={() => {
          navigate("/showmemovie");
        }}
      >
        <img
          className="btn--steps"
          src={Step4}
          alt="Watch the Demo"
          title="Global Entry Appointment Video"
          width="400px"
          height="141px"
          loading="eager"
        />
      </button>
      <br />
      <br />
      Are you tired of constantly checking the Global Entry website for open
      appointments? Are you tired of missing out on the limited availability?
      Global Entry Notifications is here to help. Our service alerts you as soon
      as a new Global Entry appointment becomes available at a location near
      you. No more refreshing the website or constantly checking for updates.
      <br />
      <br />
      With Global Entry Notifications, we will send you a text alert as soon as
      an open interview slot becomes available.
      <br />
      <br />
      <h2>Things to know:</h2>
      <li>Our alerts are sent as soon an appointment becomes available.</li>
      <li>
        {" "}
        This is NOT a subscription. You have 35 days of access for a one-time
        $20 fee.
      </li>
      <br /> <br />
      Thank you for considering Global Entry Notifications for securing your
      spot in the Global Entry program. Our goal is to help you get Global Entry
      fast and easily. Sign up now to start benefiting from our service.
    </div>
  );
}

export default HeroSection;
