import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../auth";
import "./Navbar.css";

const Navbar = ({ children }) => {
  console.log("Navbar");
  const auth = useAuth();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [signedIn, setSignedIn] = useState("Sign Up");

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    console.log("window.innerWidth=", window.innerWidth);
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const settingsTitle = () => {
    if (auth.user) {
      setSignedIn("Sign Up");
    } else {
      setSignedIn("User Settings");
    }
  };

  useEffect(() => {
    showButton();
    settingsTitle();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            Global Entry Notifications <i className="navbar-logo" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <span className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </span>
            <span className="nav-item">
              <Link
                to="/locations"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Interview Wait Times
              </Link>
            </span>
            <span className="nav-item">
              <Link
                to="/skipthewait"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                FAQ
              </Link>
            </span>
            <span className="nav-item">
              <Link
                to="/bloglist"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Blogs
              </Link>
            </span>

            {auth.user ? (
              <span className="nav-item">
                <Link
                  to="/DetermineSettings"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Settings
                </Link>
              </span>
            ) : (
              <span className="nav-item">
                <Link
                  to="/newusersignin"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  SignUp
                </Link>
              </span>
            )}
            {auth.user ? (
              <span className="nav-item">
                {/* <Link
                  to="/signout"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Log Off
                </Link> */}
              </span>
            ) : (
              <span className="nav-item">
                <Link
                  to="/signin"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Login
                </Link>
              </span>
            )}
          </ul>
        </div>
        <main>{children}</main>
      </nav>
    </>
  );
};

export default Navbar;
