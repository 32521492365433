import React from "react";
import "../App.css";
import { Helmet } from "react-helmet-async";
import LocationList from "./LocationList.js";

export default function Locations() {
  //console.log("Locations.js");
  return (
    <div>
      <Helmet>
        <title>Current Global Entry Interview Wait Times</title>
        <meta
          name="description"
          content="Select the closet Globe Entry Interview Location and see how long the wait is for an interview."
        />
        <link
          rel="canonical"
          href="https://globalentrynotifications.com/Locations"
        />
      </Helmet>
      <LocationList />
    </div>
  );
}
