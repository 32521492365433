import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { Link } from "react-router-dom";
import InterviewWaitTime from "../components/InterviewWaitTime";
import "../styles.css";

const LocationList = () => {
  console.log("In LocationList.js");
  const [locations, setLocations] = useState([]);
  const [isPending, setIsPending] = useState(true);
  //const auth = useAuth();

  const getLocations = async () => {
    //const { data, error } = await supabase.from("locations").select("*");
    const { data, error } = await supabase
      .from("locations")
      .select("*")
      .neq("address", "Permanently Closed")
      .order("countryCode", { ascending: false })
      .order("state", { ascending: true }) //.order("countryCode", false)
      .order("city", { ascending: true }); //.order("countryCode", false)
    // .order("state", true);
    if (error) throw error;
    else if (data != null) {
      console.log("locations=", data);
      setLocations(data);
      setIsPending(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <div className="form-container">
      <Helmet>
        <title>Global Entry Interview Locations</title>
        <meta
          name="description"
          content="Select the closet Globe Entry Interview Location and see how long the wait is for an interview."
        />
        <link
          rel="canonical"
          href="https://globalentrynotifications.com/Locationlist/"
        />
      </Helmet>
      <div className="header-center">
        <h1>Current Wait Times without Notifications</h1>
        <i>(Sorted by State, international locations at the end)</i>
        <br />
        <b>Click on address to see location details</b>
      </div>
      <div className="location-preview">
        {isPending && <div>Loading...</div>}
        <table className="table-container">
          <thead>
            <tr>
              <th className="table-cell-left">
                <h4>Interview Location</h4>
              </th>
              <th className="table-cell-center-white">
                <h4>Current Wait Time</h4>
              </th>
              <th className="table-cell-center-white">
                <h4>Action</h4>
              </th>
            </tr>
          </thead>
          {locations.map((locations) => (
            <tbody className="location-preview" key={locations.id}>
              <tr className="table-container">
                <td className="table-cell-left">
                  <Link to={`/locationdetails/${locations.id}`}>
                    <h2>
                      {locations.state}: {locations.name}
                    </h2>
                    <p>
                      {locations.address}, {locations.city}
                    </p>
                  </Link>
                </td>
                <InterviewWaitTime date={locations.earliest_appoinment_date} />
              </tr>
            </tbody>
          ))}
        </table>
        <br />
        <br /> <br />
        <em>
          <b>Note: </b>Closest Available Appointment
        </em>{" "}
        refers to the eariest avalable open appointment time slot. These times
        are refeshed every 10 minutes.
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default LocationList;
