import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import GEProcess1 from "../images/GetAppointmentTextAlert.jpeg";
import GEProcess2 from "../images/GetAppointmentTTLogin.jpeg";
import GEProcess3 from "../images/GetAppointmentConsent.jpeg";
import GEProcess4 from "../images/GetAppointmentLogin.jpeg";
import GEProcess5 from "../images/GetAppointmentMFA.jpeg";
import GEProcess6 from "../images/GetAppointmentDashboard.jpeg";
import GEProcess7 from "../images/GetAppointmentLocationList.jpeg";
import GEProcess8 from "../images/GetAppointmentChooseLocation.jpeg";
import GEProcess9 from "../images/GetAppointmentChooseDate.jpeg";
import "../App.css";
import "../styles.css";

const GetAnAppointment = () => {
  console.log("Start: HeroSection.js");

  return (
    <div className="form-container">
      <Helmet>
        <title>How to Secure a Global Entry Interview</title>
        <meta name="description" content="Securing an Appointment." />
        <link
          rel="canonical"
          href="https://globalentrynotifications.com/GetAnAppointment"
        />
      </Helmet>
      <div className="location-preview">
        <h1>
          What to do when you get a text notification and want to schedule your
          interview:
        </h1>{" "}
        <br />
        <ol>
          <li>
            You will be competing with other people to reserve an appointment,
            so when a text comes in, it is best act as quickly as possible.
          </li>
          <br />
          <li>
            Click on the <b>link</b> in the text you received:
            <img
              className="btn--steps"
              src={GEProcess1}
              alt="Text"
              title="Receive an appointment notification"
              width="300px"
              height="170px"
              loading="eager"
            />
            <br />
            That link will take you to the government's Trusted Traveler
            website.
          </li>
          <br />
          <br />
          <li>
            If you are not currently logged on to the Trusted Traveler website,
            do so now by clicking the <b>LOG IN</b> button:
            <br />
            <img
              className="btn--steps"
              src={GEProcess2}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="200px"
              height="400px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            You will be asked to consent to the government Security
            Notification, so press the <b>CONSENT & CONTINUE</b> button:
            <br />
            <img
              className="btn--steps"
              src={GEProcess3}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="210px"
              height="260px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            Now you can enter your Email Address and Password and click{" "}
            <b>Sign In</b>:
            <br />
            <img
              className="btn--steps"
              src={GEProcess4}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="270px"
              height="400px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            If you receive a screen to enter a one-time code, check your text
            messages and enter the <b>6 digit code</b>. Make sure you click the{" "}
            <b>Remember this browser</b> checkbox:
            <br />
            <img
              className="btn--steps"
              src={GEProcess5}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="270px"
              height="400px"
              loading="eager"
            />
            <br />
            <b>NOTE:</b> Logging in is the longest part of this process, and you
            will be logged out of the Trusted Traveler site after 15 minutes of
            inactivity.
          </li>
          <br />
          <br />
          <li>
            Once you log in, the Dashboard screen should appear. Click the blue{" "}
            <b>Schedule Interview</b> button at the botton of the{" "}
            <b>Conditionally Approved</b> window:
            <br />
            <img
              className="btn--steps"
              src={GEProcess6}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="270px"
              height="480px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            Select your interview location by scrolling down and clicking on the
            appropriate Enrollment Center:
            <br />
            <img
              className="btn--steps"
              src={GEProcess7}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="270px"
              height="400px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            An small window will open and allow you to click the{" "}
            <b>CHOOSE THIS LOCATION</b> button:
            <br />
            <br />
            <img
              className="btn--steps"
              src={GEProcess8}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="270px"
              height="400px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            Select the date and time you want!
            <br />
            <img
              className="btn--steps"
              src={GEProcess9}
              alt="The Global Entry Process"
              title="Create Trusted Traveler Account"
              width="270px"
              height="400px"
              loading="eager"
            />
          </li>
          <br />
          <br />
          <li>
            Finally, if you are happy with your date, send us a text that says{" "}
            <b>Stop</b>, and we'll stop send you text messages. If you want to
            continue to try for a better date, text us the date of your
            appointment, and we will only notify you of appointments before your
            scheduled interview.
          </li>
        </ol>
        <br />
        <br />
        <Link to={`/`}>
          <h3>Back to Home</h3>
        </Link>
        <br />
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default GetAnAppointment;
