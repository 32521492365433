import { supabase } from "./supabase";
import { useState, useEffect, useContext, createContext } from "react";

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  console.log("Auth.js: in useProvider");
  const [user, setUser] = useState(null);

  const login = async (cellphone) => {
    const { error, user } = await supabase.auth.signInWithOtp({
      phone: cellphone,
    });

    if (error) {
      console.log(error);
    }

    return { error, user };
  };

  const verify = async (cellphone, authtoken) => {
    console.log("Auth.js: in verify");
    const { error, user } = await supabase.auth.verifyOtp({
      phone: cellphone,
      token: authtoken,
      type: "sms",
    });

    console.log("verify in auth:", cellphone, authtoken);

    if (error) {
      console.log(error);
    }

    return { error, user };
  };

  const logout = async () => {
    console.log("Auth.js: in logout");
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.log("Auth.js: in logout Error=", error);
    }

    setUser(null);
  };

  useEffect(() => {
    const { data, error } = supabase.auth.getUser().then((value) => {
      console.log("in useProvider getUser");
      if (value.data?.user) {
        setUser(value.data.user);
      }
    });

    const auth = supabase.auth.onAuthStateChange((event, session) => {
      console.log("in useProvider onAuthStateChange");
      if (event === "SIGNED_IN") {
        setUser(session.user);
      }

      if (event === "SIGNED_OUT") {
        setUser(null);
      }
    });

    return () => auth.data.signOut;
  }, []);

  return {
    user,
    login,
    verify,
    logout,
  };
}
