import { Link } from "react-router-dom";

const InterviewWaitTime = ({ date }) => {
  //  create a new Date object
  let dayDiff;
  let noappointments = null;
  let classyellow = null;
  let classgreen = null;
  let classred = false;
  let onlyOneDay = false;

  if (date == null) {
    noappointments = true;
  } else {
    const appointment_Date = new Date(date);
    appointment_Date.setDate(appointment_Date.getDate() + 1);
    const today_Date = new Date();
    console.log("appointment_Date: ", appointment_Date.toLocaleDateString());
    console.log("today_Date: ", today_Date.toLocaleDateString());

    dayDiff = Math.floor(
      (Date.UTC(
        appointment_Date.getFullYear(),
        appointment_Date.getMonth(),
        appointment_Date.getDate()
      ) -
        Date.UTC(
          today_Date.getFullYear(),
          today_Date.getMonth(),
          today_Date.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
    if (dayDiff === 1) {
      onlyOneDay = true;
    }
    if (dayDiff <= 35) {
      classgreen = true;
    } else if (dayDiff <= 60) {
      classyellow = true;
    } else if (dayDiff > 60) {
      classred = true;
    }
  }

  // return the Date object
  return (
    <>
      {noappointments ? (
        <>
          <td className="table-cell-center-red">{"Current wait: 365 Days"}</td>
          <td className="table-cell-center-notify">
            <Link to={`/SkipTheWait`}>
              <b>Click here to find an appointment sooner</b>
            </Link>
          </td>
        </>
      ) : null}
      {classgreen ? (
        <>
          <td className="table-cell-center-green">
            {"Current wait: "}
            <br />
            <b>{dayDiff}</b>
            {onlyOneDay ? " day" : " days"}
          </td>
          <td className="table-cell-center-tts">
            <a
              href="https://ttp.cbp.dhs.gov/schedulerui/schedule-interview/location?lang=en&vo=true&returnUrl=ttp-external&service=UP"
              target="_blank"
            >
              Schedule an appointment
              <br />
              on the TTP site.
            </a>
          </td>
        </>
      ) : null}
      {classyellow ? (
        <>
          <td className="table-cell-center-yellow">
            {"Current wait: "}
            <br />
            <b>{dayDiff}</b>
            {" days"}
          </td>
          <td className="table-cell-center-notify">
            <Link to={`/SkipTheWait`}>
              <b>Click here to find an appointment sooner</b>
            </Link>
          </td>
        </>
      ) : null}
      {classred ? (
        <>
          <td className="table-cell-center-redish">
            {"Current wait: "}
            <br />
            <b>{dayDiff}</b>
            {" days"}
          </td>
          <td className="table-cell-center-notify">
            <Link to={`/SkipTheWait`}>
              <b>Click here to find an appointment sooner</b>
            </Link>
          </td>
        </>
      ) : null}
    </>
  );
};

export default InterviewWaitTime;
