import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { Link, useNavigate } from "react-router-dom";

import "../styles.css";

function BlogList() {
  const navigate = useNavigate();
  const headerText = "Learn about Global Entry";
  const paragraghText =
    "This is a <b>mighty</b> fine paragraph. You can add lists: <ul> <li> part 1 </li> <li> part 2 </li> </ul> <br/> And other shit like numbers: <ol> <li> part 1 </li> <li> part 2 </li></ol>";

  const [html, setHtml] = useState("");
  const [blogList, setBlogs] = useState([]);
  const [isPending, setIsPending] = useState(true);

  const getBlogs = async () => {
    //const { data, error } = await supabase.from("blogList").select("*");
    const { data, error } = await supabase
      .from("blog_titles")
      .select("*")
      .order("id", { ascending: true });

    if (error) throw error;
    else if (data != null) {
      console.log("blog_titles=", data);
      setBlogs(data);
      setIsPending(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    setHtml(paragraghText);
  }, [html]);

  return (
    <div className="form-container">
      <Helmet>
        <title>Learn about Global Entry</title>
        <meta name="description" content="Blogs about Global Entry." />
        <meta
          name="description"
          content="Select the closet Globe Entry Interview Location and see how long the wait is for an interview."
        />
        <link
          rel="canonical"
          href="https://globalentrynotifications.com/Locationlist/"
        />
      </Helmet>
      <div className="header-center">
        <h1>Global Entry Informational Blogs</h1>
        <i>(Click on any topic for details.)</i>
        <br />
        <br />
      </div>
      <div className="location-preview">
        {isPending && <div>Loading...</div>}

        {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
        {blogList.map((blogList) => (
          <tbody className="location-preview" key={blogList.id}>
            <Link to={`/blogs/${blogList.url_text}`}>
              <h2>{blogList.titles}</h2>
            </Link>
          </tbody>
        ))}

        <br />
        <br />
        <br />
        <button
          className="checkout-button"
          onClick={() => {
            navigate("/");
          }}
        >
          Return to Home
        </button>
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
}

export default BlogList;
