import React, { useState } from "react";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../styles.css";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
  console.log("In SignInForm");
  const auth = useAuth();
  const [cellphone, setCellPhone] = useState("");
  const [authtoken, setAuthToken] = useState("");
  const [message, setMessage] = useState("");
  const [codesent, setCodesent] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();

    console.log("Cellphone=", cellphone);

    const signIn = await auth.login(cellphone);

    if (signIn.error) {
      setMessage(signIn.error.message);
    } else {
      setMessage("Login link has been sent.");
      setCodesent(!codesent);
    }
  };

  const handleVerifyAccount = async (e) => {
    e.preventDefault();
    const verifyAccount = await auth.verify(cellphone, authtoken, "sms");

    console.log("Start 3");
    if (verifyAccount.error) {
      setMessage(verifyAccount.error.message);
    } else {
      setMessage("Redirecting...");
    }
    navigate("/");
  };

  return (
    <div className="form-container">
      <Helmet>
        <title>Existing User Sign-in for Global Entry Notifications</title>
        <meta
          name="description"
          content="Sign into the Globe Entry Notifications System."
        />
      </Helmet>
      <div className="location-preview">
        {message && message}
        <h1>Welcome to User SignIn </h1>
        <p>
          <label>
            <br />
            <br /> Let's verify your cell phone number. Please make sure that
            the country code on the left is correct, then enter your area code
            and cell number in the text box.
            <br />
          </label>
        </p>
        <form className="form-field" onSubmit={handleSignIn}>
          <PhoneInput
            className="inputbox"
            defaultCountry="US"
            value={cellphone}
            onChange={(cellphone) => setCellPhone(cellphone)}
          />
          <p>
            <br />
            <label>
              Verify your cell number is correct, then press the{" "}
              <b>"Get Code"</b> button below to recieve you authorization code:
              <br />
            </label>
          </p>

          <button className="checkout-button" type={"submit"}>
            Get Code
          </button>
        </form>
        <br /> <br />
        {codesent ? (
          <>
            <p>
              <label>
                Type the 6 digit authorization code below.
                <br />
              </label>
            </p>

            <form className="form-field" onSubmit={handleVerifyAccount}>
              <input
                className="inputbox"
                type="authtoken"
                value={authtoken}
                onChange={(e) => setAuthToken(e.target.value)}
              />
              <br />
              <button className="checkout-button" type={"submit"}>
                Verify Authorization Code
              </button>
            </form>
          </>
        ) : (
          <br />
        )}
        {/* {auth.user ? (
          <form>
            <p>
              <br />
              <br />
              <label>
                You have successfully verifyed you cell phone number, now let's{" "}
                set up your appointment alert preferences.
                <br />
              </label>
              <button to={"/createsettings"} type={"submit"}>
                Step 2: Setup Alert Perferences
              </button>
            </p>
          </form>
        ) : (
          <br />
        )} */}
      </div>
      {/* Codesent={codesent ? <>True</> : <>False</>} */}
    </div>
  );
};

export default SignInForm;
