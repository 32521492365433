import React from "react";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import DetermineSettings from "./DetermineSettings";
import NewUserSignIn from "./NewUserSignIn";
import "../styles.css";

export default function Settings() {
  //alert("Settings");
  console.log("Settings.js");
  const auth = useAuth();
  console.log("Settings: auth=", auth);
  return (
    <div className="form-container">
      <Helmet>
        <title>Review User Settings for Global Entry Notifications</title>
        <meta
          name="description"
          content="Review or change your criteria for being notified about a newly available Global Entry Interview."
        />
      </Helmet>{" "}
      {auth.user ? (
        <div>
          <DetermineSettings />
        </div>
      ) : (
        <div>
          <NewUserSignIn />
        </div>
      )}
    </div>
  );
}
