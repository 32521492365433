import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles.css";
import "../Button.css";
//import { defaultStyles } from "react-select/dist/declarations/src/styles";

const CreateSettings = () => {
  //("In Create Settings Start");
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    appointmentDate: "",
    locationSelect: [],
    checkboxdays: "",
  });
  const [errors, setErrors] = useState({});
  const defaultDate = new Date();
  // console.log("1Create startup default:", defaultDate);
  defaultDate.setDate(defaultDate.getDate() + 35);
  // console.log("2Create startup default:", defaultDate);
  const [dateStart, setDateStart] = useState(defaultDate);
  const [current_appointment_date, setCurrent_appointment_date] =
    useState(defaultDate);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [location_ids, setLocation_ids] = useState([]);
  const [available_sunday, setAvailable_sunday] = useState(true);
  const [available_monday, setAvailable_monday] = useState(true);
  const [available_tuesday, setAvailable_tuesday] = useState(true);
  const [available_wednesday, setAvailable_wednesday] = useState(true);
  const [available_thursday, setAvailable_thursday] = useState(true);
  const [available_friday, setAvailable_friday] = useState(true);
  const [available_saturday, setAvailable_saturday] = useState(true);
  const [disclaimerchecked, setDisclaimerChecked] = useState(false);
  const [locations, setLocations] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const auth = useAuth();
  // console.log("useAuth=", auth);
  const navigate = useNavigate();
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  //const [loading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  //const [originalLocations, setOriginalLocations] = useState([]);
  //const regex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
  //const [keepMyLocations, setKeepMyLocations] = useState([false]);
  // console.log("in start: dateStart=", dateStart.toLocaleDateString("us"));
  // function formatDate(datestring) {
  //   // const temp = datestring.replace("-", "/");
  //   const currentappdate = new Date(datestring.replace("-", "/"));
  //   // console.log("formatDate=", currentappdate);
  //   return currentappdate.toLocaleDateString("en-US");
  // }

  const handleDisclaimerChange = () => {
    setDisclaimerChecked(!disclaimerchecked);
  };

  // function formatPhoneNumber(phoneCountryCodeString, phoneNumberString) {
  //   var cleaned = ("" + phoneCountryCodeString + phoneNumberString).replace(
  //     /\D/g,
  //     ""
  //   );
  //   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     var intlCode = match[1] ? "+1 " : "";
  //     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  //   }
  //   return null;
  // }

  // console.log("getLocations1");
  const getLocations = async () => {
    const { data, error } = await supabase
      .from("locations")
      .select("id, name, city, state")
      .neq("address", "Permanently Closed")
      .order("countryCode", { ascending: false })
      .order("state", { ascending: true });
    if (error) throw error;
    if (data !== null) {
      // console.log("locations=", data);
      setLocations(data);
      setIsPending(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  // console.log("getLocations2");

  const locationoptions = locations.map((d, index) => ({
    value: d.id,
    label: d.state + ", " + d.city + ": " + d.name,
  }));

  // let temparray = location_ids
  //   .toString()
  //   .replace("[", "")
  //   .replace("]", "")
  //   .split(",");

  // console.log("getLocations3");

  const handleLocationChange = (e) => {
    // console.log("handleLocationChange e", e);
    setLocation_ids(Array.isArray(e) ? e.map((x) => x.value) : []);
    // console.log("location_ids=", location_ids);
  };

  const handleClicksave = async (e) => {
    //e.preventDefault();
    let formattedLocations = JSON.stringify(selectedLocations);
    // console.log("handleSave formattedLocations=", formattedLocations);

    // console.log("dateStart=", dateStart.toLocaleDateString("us"));

    formattedLocations = formattedLocations.replaceAll("[", "");
    formattedLocations = formattedLocations.replaceAll("]", "");

    setLocation_ids(formattedLocations);
    // console.log("handleSave pre-upsert location ids:", location_ids);

    // console.log("id:", auth.user.id);
    // console.log("first_name:", first_name);
    // console.log("last_name:", last_name);
    // console.log("location_ids:", location_ids);
    // console.log("available_sunday:", available_sunday);
    // console.log("available_monday:", available_monday);
    // console.log("available_tuesday:", available_tuesday);
    // console.log("available_wednesday:", available_wednesday);
    // console.log("available_thursday:", available_thursday);
    // console.log("available_friday:", available_friday);
    // console.log("available_saturday:", available_saturday);
    // console.log("current_appointment_date:", current_appointment_date);

    const { data, error } = await supabase
      .from("preferences")
      .upsert({
        id: auth.user.id,
        first_name: form.first_name,
        last_name: form.last_name,
        location_ids: location_ids,
        available_sunday: available_sunday,
        available_monday: available_monday,
        available_tuesday: available_tuesday,
        available_wednesday: available_wednesday,
        available_thursday: available_thursday,
        available_friday: available_friday,
        available_saturday: available_saturday,
        current_appointment_date:
          current_appointment_date.toLocaleDateString("en-US"),
        settings_updated_at: new Date().toISOString().toLocaleString("zh-TW"),
      })
      .select();

    if (error) {
      // console.log("Please fill in all the fields correctly.", error);
    }

    if (data) {
      // console.log("In CreateSettings: data=", data);
      let today = new Date();
      let tmpday;
      if (data[0].subscription_end_date)
        tmpday = new Date(data[0].subscription_end_date.replace("-", "/"));
      if (data[0].subscription_paid !== true) {
        navigate("/Checkout");
      } else if (data[0].subscription_end_date && today > tmpday) {
        navigate("/Checkout");
      } else {
        navigate("/getanappointment");
      }
    }

    //updatePreferences();
  };

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  const location1 = 6940;
  let index = -1;

  // if (locationoptions) {
  //   index = getIndex(location1, locationoptions, "value");
  // }

  function onChangeDateHandler(value) {
    if (value != null) {
      setDateStart(value);
      setCurrent_appointment_date(value);
    }
    // console.log("onChangeDateHandler value=", dateStart);
  }

  const validateForm = () => {
    const { first_name, last_name } = form;
    const newErrors = {};
    const todaysDate = new Date();

    if (!first_name || first_name === "")
      newErrors.first_name = "First name cannot be blank.";
    else setFirst_name(first_name);

    if (!last_name || last_name === "")
      newErrors.last_name = "Last name cannot be blank.";
    else setLast_name(last_name);

    if (!current_appointment_date || current_appointment_date === "")
      newErrors.appointmentDate = "Date cannot be blank.";
    else if (current_appointment_date < todaysDate)
      newErrors.appointmentDate = "Date must be greater than today.";
    if (!location_ids || location_ids.length === 0)
      newErrors.locationSelect = "Select at least 1 location.";
    else if (location_ids.length > 3)
      newErrors.locationSelect = "Select no more than 3 locations.";
    if (
      !(
        available_sunday ||
        available_monday ||
        available_tuesday ||
        available_wednesday ||
        available_thursday ||
        available_friday ||
        available_saturday
      )
    ) {
      newErrors.checkboxdays = "You must check at least one day.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      handleClicksave();
    }
  };

  return (
    <div className="form-container">
      <Helmet>
        <title>Create User Settings for Global Entry Notifications</title>
        <meta
          name="description"
          content="Create your personal criteria for being notified about newly available Global Entry Interviews."
        />
      </Helmet>

      <h1>User Settings</h1>
      {isPending && <div>Loading...</div>}
      {/* <form onSubmit={handleClicksave}> */}
      <form>
        <Form.Group className="form-field" controlId="first_name">
          <Form.Label>
            <b>First Name:</b>
          </Form.Label>

          <Form.Control
            className="inputbox"
            type="text"
            placeholder="Please enter your first name"
            value={form.first_name}
            onChange={(e) => setField("first_name", e.target.value)}
            isInvalid={!!errors.first_name}
          />
          <Form.Control.Feedback type="invalid" />
          {errors.first_name && (
            <span className="errorMsg">{errors.first_name}</span>
          )}
        </Form.Group>
        <Form.Group className="form-field" controlId="last-name">
          <Form.Label>
            <b>Last Name:</b>
          </Form.Label>

          <Form.Control
            className="inputbox"
            type="text"
            placeholder="Please enter your last name"
            value={form.last_name}
            onChange={(e) => setField("last_name", e.target.value)}
            isInvalid={!!errors.last_name}
          />
          <Form.Control.Feedback type="invalid" />
          {errors.last_name && (
            <span className="errorMsg">{errors.last_name}</span>
          )}
        </Form.Group>
        <Form.Group className="form-field" controlId="current_appointment_date">
          <Form.Label>
            <b>Notify me of appointments before this date:</b>
          </Form.Label>

          <DatePicker
            id="dateStartEnd"
            selected={dateStart}
            onChange={onChangeDateHandler}
            dateFormat="MM/dd/yyyy"
            className={"form-control-sm"}
            showDisabledMonthNavigation
          />
          <Form.Control.Feedback type="invalid" />
          {errors.appointmentDate && (
            <span className="errorMsg">{errors.appointmentDate}</span>
          )}
        </Form.Group>
        <Form.Group className="form-field" controlId="getlocations">
          <Form.Label>
            <b>
              Please select up to 3 desired interview locations: (
              <i>The list is sorted by state</i>)
            </b>
          </Form.Label>

          <Select
            className="dropdown"
            placeholder="Select Option"
            //defaultValue={[locationoptions[index]]}
            options={locationoptions} // set list of the data
            onChange={handleLocationChange} // assign onChange function
            isOptionDisabled={() => location_ids.length >= 3}
            isMulti
            isClearable
          />
          <Form.Control.Feedback type="invalid" />
          {errors.locationSelect && (
            <span className="errorMsg">{errors.locationSelect}</span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <b>Notify me of appointments on these days of the week:</b>
          </Form.Label>
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Sunday"
            checked={available_sunday}
            onChange={(e) => setAvailable_sunday(!available_sunday)}
          />
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Monday"
            checked={available_monday}
            value={available_monday}
            onChange={(e) => setAvailable_monday(!available_monday)}
          />
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Tuesday"
            checked={available_tuesday}
            value={available_tuesday}
            onChange={(e) => setAvailable_tuesday(!available_tuesday)}
          />
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Wednesday"
            //defaultValue={setAvailable_wednesday}
            checked={available_wednesday}
            value={available_wednesday}
            onChange={(e) => setAvailable_wednesday(!available_wednesday)}
          />
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Thursday"
            checked={available_thursday}
            value={available_thursday}
            onChange={(e) => setAvailable_thursday(!available_thursday)}
          />
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Friday"
            checked={available_friday}
            value={available_friday}
            onChange={(e) => setAvailable_friday(!available_friday)}
          />
          <Form.Check
            className="checkbox"
            type="checkbox"
            label="Saturday"
            checked={available_saturday}
            value={available_saturday}
            onChange={(e) => setAvailable_saturday(!available_saturday)}
          />
          <Form.Control.Feedback type="invalid" />
          {errors.checkboxdays && (
            <span className="errorMsg">{errors.checkboxdays}</span>
          )}
        </Form.Group>
        <br />
        <br />
        <Form.Group className="form-field" controlId="appointmentdays">
          <div className="disclaimer-preview">
            The Global Entry Appointment Notification System (GEAAS) will
            communicate with you through SMS (i.e. text) messaging. Your
            cellular plan may charge to receive these messages. Depending on
            appointment available, the GEAAS may send as many as 20 SMS messages
            per day.
            <br />
            <br />
            By clicking OK box below, you agree to receive SMS messages from
            GEAAS related to notifying you about open appointments, and
            occasionally verifying your identity when user settings are setup or
            changed. We do not sell or share any private information with any
            outside entities.
            <br />
            <br />
            {/* </div>
        <div className="form-field"> */}
            <label>
              <input
                className="checkbox"
                type="checkbox"
                onChange={handleDisclaimerChange}
              />
              <b>OK</b>
            </label>
          </div>
          <br />
          <button
            type="submit"
            onClick={handleSubmit}
            className="checkout-button"
            disabled={disclaimerchecked ? false : true}
          >
            Save
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
        </Form.Group>
      </form>
      <br />
      <br />
    </div>
  );
};

export default CreateSettings;
