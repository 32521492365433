import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import "./popup.css";

const Popup = (props) => {
  const [flip, setFlip] = useState(false);
  const aminProps = useSpring({
    config: { duration: 1500 },
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
    reverse: flip,
    //delay: 0,
    //onRest: () => setFlip(!flip),
  });

  return props.trigger ? (
    <>
      <br />
      {props.descriptionLine1 ? (
        <b>Average appointment openings found per location:</b>
      ) : (
        <br />
      )}
      <h4>
        <animated.div style={aminProps}>
          {props.descriptionLine1}
          <u>{props.average}</u>
          {props.descriptionLine2}
          <br />
          {props.enrollmentcenter}
        </animated.div>
      </h4>
      <br />
    </>
  ) : (
    ""
  );
};

export default Popup;
