import React, { useState } from "react";
import { useEffect } from "react";

const LocationApptsFound = (props) => {
  const locationDetails = props.locationDetails;
  const [isZero, setIsZero] = useState(true);

  console.log(props, locationDetails);
  const [newAve, setNewAve] = useState("");

  useEffect(() => {
    setNewAve(
      Math.round(
        (locationDetails[0].avg_appointments_found + Number.EPSILON) * 10
      ) / 10
    );
    if (locationDetails[0].avg_appointments_found == null) setIsZero(false);
  }, []);

  return (
    <>
      {isZero ? (
        <b>
          On average, we find {newAve} appointments per day for the{" "}
          {locationDetails[0].name}{" "}
        </b>
      ) : (
        <></>
      )}
    </>
  );
};
export default LocationApptsFound;
