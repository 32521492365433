import React, { useState, useEffect } from "react";
import LocationApptsFound from "../components/LocationApptsFound";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { Link, useParams } from "react-router-dom";
import InterviewCalcButton from "../components/InterviewCalcButton";
import "../App.css";
import "../styles.css";

const LocationDetails = () => {
  const [locationDetails, setLocationDetails] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [textLine1, setTextLine1] = useState("");
  const [textLine2, setTextLine2] = useState("");
  const [textAve, setTextAve] = useState("");
  const [goodRead, setGoodRead] = useState(false);
  // const auth = useAuth();
  const { id } = useParams();

  function formatPhoneNumber(phoneCountryCodeString, phoneNumberString) {
    var cleaned = ("" + phoneCountryCodeString + phoneNumberString).replace(
      /\D/g,
      ""
    );
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const getLocationDetails = async () => {
    console.log("LocationDetails id=", id);
    const { loading, error, data } = await supabase
      .from("locations")
      .select("*")
      .eq("id", id);
    if (error) throw error;
    if (data != null) {
      console.log(data);
      setLocationDetails(data);
      setIsPending(false);
    }
  };

  useEffect(() => {
    getLocationDetails();
  }, []);

  return (
    <div className="form-container">
      <Helmet>
        <title>Global Entry Interview Location Details</title>
        <meta
          name="description"
          content="Learn directions and other details about traveling to the Globe Entry Interview location."
        />
      </Helmet>
      <div className="location-preview">
        {isPending && <div>Loading...</div>}
        {locationDetails.map((locationDetail) => (
          <div key={locationDetail.id}>
            <div to={`/locationdetail/${locationDetail.id}`}>
              <h1>{locationDetail.name}</h1>
              <br />
              <h2>
                <LocationApptsFound locationDetails={locationDetails} />
              </h2>
              <br />
              <br />
              <b>Address:</b> {locationDetail.address}
              <div className="location-addressindent">
                {locationDetail.city}, {locationDetail.state}
              </div>
              <br />
              <b>Phone Number: </b>
              {formatPhoneNumber(
                locationDetail.phoneCountryCode,
                locationDetail.phoneNumber
              )}
              <br /> <br />
              {locationDetail.directions.length > 0 && (
                <>
                  <b>Directions:</b> {locationDetail.directions} <br />
                </>
              )}{" "}
              {locationDetail.notes.length > 3 && (
                <span>
                  <br />
                  <b>Other Notes:</b> {locationDetail.notes}
                </span>
              )}
              <br />
              <br />
              <br />
              <InterviewCalcButton
                date={locationDetail.earliest_appoinment_date}
              />
              <br />
              <br /> <br />
              <Link to={`/locations`}>
                <h3>Back to Location List</h3>
              </Link>
              <br /> <br />
            </div>
          </div>
        ))}
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default LocationDetails;
