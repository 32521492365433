const InterviewCalcButton = ({ date }) => {
  //  create a new Date object
  let dayDiff;
  let dayUnderLimit = true;

  if (date == null) {
    dayUnderLimit = false;
  } else {
    const appointment_Date = new Date(date);
    appointment_Date.setDate(appointment_Date.getDate() + 1);
    const today_Date = new Date();
    console.log("appointment_Date: ", appointment_Date.toLocaleDateString());
    console.log("today_Date: ", today_Date.toLocaleDateString());

    dayDiff = Math.floor(
      (Date.UTC(
        appointment_Date.getFullYear(),
        appointment_Date.getMonth(),
        appointment_Date.getDate()
      ) -
        Date.UTC(
          today_Date.getFullYear(),
          today_Date.getMonth(),
          today_Date.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
    if (dayDiff > 35) dayUnderLimit = false;
  }

  // return the Date object
  return (
    <>
      {" "}
      {dayUnderLimit ? (
        <a
          className="checkout-button"
          href="https://ttp.cbp.dhs.gov/schedulerui/schedule-interview/location?lang=en&vo=true&returnUrl=ttp-external&service=UP"
          target="_blank"
          rel="noreferrer"
        >
          Schedule an appointment on the TTP site.
        </a>
      ) : (
        <a className="checkout-button" href="/SkipTheWait" target="_self">
          <b>Very long wait. Find an appointment sooner!</b>
        </a>
      )}
    </>
  );
};

export default InterviewCalcButton;
