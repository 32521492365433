import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  console.log("ProtectedRoute auth=", auth);
  return auth.user ? children : <Navigate to={"/settings"} />;
};
export default ProtectedRoute;
