import React from "react";
import "../styles.css";

const TestPage = () => {
  return (
    <div className="form-container">
      <div className="location-preview">
        <h1>Test Page</h1>
      </div>
    </div>
  );
};

export default TestPage;
