import React from "react";
import "../App.css";
import { Helmet } from "react-helmet-async";
import SignInForm from "./SignInForm.js";

export default function SignIn() {
  console.log("SignIn.js");
  return (
    <div>
      <Helmet>
        <title>User Sign-in for Global Entry Notifications</title>
        <meta
          name="description"
          content="Sign into the Globe Entry Notifications System."
        />
      </Helmet>
      <SignInForm />
      <br />
      <br />
      <br />
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
}
