import React, { useEffect } from 'react';

function TTPRedirect() {
  useEffect(() => {
    console.log("Redirecting...");
    window.location.href = "https://ttp.cbp.dhs.gov/schedulerui/schedule-interview/location?lang=en&vo=true&returnUrl=ttp-external&service=UP";
  }, []);

  return <div>Redirecting...</div>;
}


export default TTPRedirect;
