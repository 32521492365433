import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../App.css";
import "../styles.css";
import "../HeroSection.css";
// import "react-html5video/dist/styles.css";
import YoutubeEmbed from "../components/YoutubeEmbed";

const ShowMeMovie = () => {
  console.log("Start: HeroSection.js");

  return (
    <div className="form-container">
      <Helmet>
        <title>Global Entry Schedule Interview Video</title>
        <meta
          name="description"
          content="Play a short video of the Global Entry Notifications process."
        />
        <link
          rel="canonical"
          href="https://globalentrynotifications.com/showmemovie"
        />
      </Helmet>
      <div className="herolocation-preview">
        <h1>How SMS Notifications Work:</h1>
        <YoutubeEmbed embedId="G7lFC-mTlG4" />
        <br />
        <br />
        <br />
        <Link to={`/`}>
          <h3>Back to Home</h3>
        </Link>
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};

export default ShowMeMovie;
