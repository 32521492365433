import React, { useEffect } from "react";
import { useAuth } from "../auth";
import { supabase } from "../supabase";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../styles.css";

function Success() {
  const auth = useAuth();
  console.log("1 handlePaidProcess auth=", auth);
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 46);

  const navigate = useNavigate();

  const handlePaidProcess = async () => {
    if (!auth.user) return;
    const { data, error } = await supabase
      .from("preferences")
      .upsert({
        id: auth.user.id,
        subscription_paid: true,
        subscription_end_date: endDate.toLocaleDateString("en-US"),
        payment_made_at: new Date().toISOString().toLocaleString("zh-TW"),
      })
      .eq("id", auth.user.id)
      .select();

    if (error) {
      console.log("Update failed.", error);
    }
    if (data) {
      console.log("Update succeeded.", data);
    }
  };

  useEffect(() => {
    handlePaidProcess();
  }, [auth]);

  return (
    <div className="form-container">
      <Helmet>
        <title>Global Entry Notification Checkout Page</title>
        <meta
          name="description"
          content="Purchase 35 day access to GlobalEntryNotifications.com."
        />
      </Helmet>
      <div className="location-preview">
        {auth.user ? (
          <>
            <h1>Thank you for your purchase!</h1>
            <br />
            <br />
            <b>Step 3:</b>
            <br />
            <br />
            <span>
              Now go to Settings and set up your Global Entry Notifications
              criteria!
              <br />
            </span>{" "}
            <br />
            <br />
            <button
              className="checkout-button"
              onClick={() => {
                navigate("/"); //Workflow Change 7.5.2023 from: navigate("/CreateSettings");
              }}
            >
              Go to Home Page
            </button>
            <br />
            <br />
          </>
        ) : (
          <>
            <h1>Saving...</h1>

            {/* {<StateCommon />} */}
          </>
        )}
      </div>
    </div>
  );
}

export default Success;
