import React, { useState, useEffect } from "react";
import ReadOnlySettings from "./ReadOnlySettings";
import CreateSettings from "./CreateSettings";
import { supabase } from "../supabase";
import { useAuth } from "../auth";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import "../styles.css";
import Checkout from "./Checkout";

const DetermineSettings = () => {
  console.log("Settings.js");
  const auth = useAuth();
  const navigate = useNavigate();
  console.log("DetermineSettings id=", auth.user.id);
  const [userHasData, setUserHasData] = useState(false);
  const [userHasPaid, setUserHasPaid] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [goodRead, setGoodRead] = useState(false);

  const getSettings = async () => {
    const { data } = await supabase
      .from("preferences")
      .select("*")
      .eq("id", auth.user.id);

    if (data) {
      if (data.length !== 0) {
        if (data[0].current_appointment_date.length !== 0) setUserHasData(true);
        if (data[0].subscription_paid === true) {
          setUserHasPaid(true);
        }
      }
      // else
      //   navigate("/Checkout");          Workflow Change 7.5.2023
      setIsPending(false);
      setGoodRead(true);
    }
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-container">
      <Helmet>
        <title>Review Settings </title>
        <meta
          name="description"
          content="Review your criteria for being notified about a newly available Global Entry Interview."
        />
      </Helmet>
      <h1>User Settings</h1>
      {isPending && <>Loading...</>}
      {goodRead && (
        <>
          {userHasData ? (
            <>
              {userHasPaid ? (
                <>
                  <ReadOnlySettings />
                </>
              ) : (
                <>
                  <Checkout />
                </>
              )}
            </>
          ) : (
            <>
              <CreateSettings />
            </>
          )}
        </>
      )}
      <br /> <br /> <br />
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
};
export default DetermineSettings;
