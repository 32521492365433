import React from "react";
import HeroSection from "../HeroSection";
import { Helmet } from "react-helmet-async";
import Footer from "../Footer";
import "../styles.css";

const Home = () => {
  console.log("Home");
  return (
    <div className="form-container">
      <Helmet>
        <title>Global Entry Notifications Home Page</title>
        <meta
          name="description"
          content="Your starting place to skipping the line and getting a quick Globel Entry Interview."
        />
        <link rel="canonical" href="https://globalentrynotifications.com" />
      </Helmet>
      <HeroSection />
      <Footer></Footer>
      {/* <Cards /> */}
    </div>
  );
};

export default Home;
