import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { supabase } from "../supabase";
import { Link, useParams } from "react-router-dom";

import "../styles.css";

function Blogs() {
  const navigate = useNavigate();
  const headerText = "Learn about Global Entry";
  const paragraghText =
    "This is a <b>mighty</b> fine paragraph. You  an add lists: <ul> <li> part 1 </li> <li> part 2 </li> </ul> <br/> And other shit like numbers: <ol> <li> part 1 </li> <li> part 2 </li></ol>";

  const [html, setHtml] = useState("");
  const [blogTitle, setBlogTitle] = useState([]);
  const [blogSection, setBlogSection] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const { id } = useParams();

  const getTitle = async () => {
    console.log("In Get Titles 1:", id);
    const { error, data } = await supabase
      .from("blog_titles")
      .select("*")
      .eq("url_text", id);
    // .order("blog_sequence", { ascending: true });
    console.log("In Get Titles 2");
    if (error) throw error;
    else if (data != null) {
      console.log("blog_title=", data);
      setBlogTitle(data[0].titles);
      console.log("titleData.titles=", blogTitle);
      setIsPending(false);
    }
  };

  useEffect(() => {
    getTitle();
  }, []);

  const getBlogs = async () => {
    const { error, data } = await supabase
      .from("blog_sections")
      .select("blog_title_id, blog_sequence, blog_text")
      .eq("url_text,", id)
      .order("blog_sequence", { ascending: true });

    if (error) throw error;
    else if (data != null) {
      console.log("blog_sections=", data);
      setBlogSection(data);
      setIsPending(false);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    setHtml(paragraghText);
  }, [html]);

  return (
    <div className="form-container">
      <Helmet>
        <title>Learn about Global Entry</title>
        <meta name="description" content="Blogs about Global Entry." />
      </Helmet>
      <div>
        <br />
        <h1>{blogTitle}</h1>
        {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
        <br />
        <br />
        {blogSection.map((blogSection) => (
          <p key={blogSection.blog_title_id}>
            {/* <h2>{blogSection.blog_text}</h2> */}
            <div
              dangerouslySetInnerHTML={{ __html: blogSection.blog_text }}
            ></div>
          </p>
        ))}
        {/* {blogSection.map((blogsections) => (
          <tbody className="location-preview" key={blogsections.blog_title_id}>
            <tr className="table-container">
              <td className="table-cell-left">
                <h2>{blogsections.blog_text}</h2>
              </td>
            </tr>
          </tbody>
        ))} */}
        <br />
        <br />
        <br />
        <button
          className="checkout-button"
          onClick={() => {
            navigate("/bloglist");
          }}
        >
          Back to Blog List
        </button>
      </div>
      <p>&copy;GlobalEntryNotifications.com</p>
    </div>
  );
}

export default Blogs;
