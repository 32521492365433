import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ProductImage from "../images/ProductIcon.jpg";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabase";
import { useAuth } from "../auth";

import "../styles.css";

let stripePromise;

const stripeAnonKey = process.env.REACT_APP_STRIPE_KEY;
const stripeRegularPriceKey = process.env.REACT_APP_STRIPE_REGULAR_PRICE_KEY;
const stripeDiscountPriceKey = process.env.REACT_APP_STRIPE_DISCOUNT_PRICE_KEY;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeAnonKey);
  }
  return stripePromise;
};

const Checkout = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [stripePrice, setStripePrice] = useState(stripeRegularPriceKey);
  const [displayPrice, setDisplayPrice] = useState("$20.00");

  const getSettings = async () => {
    const { data } = await supabase
      .from("preferences")
      .select("*")
      .eq("id", auth.user.id);
    if (data) {
      if (data.length !== 0) {
        if (data[0].payment_drip_sent === true) {
          setStripePrice(stripeDiscountPriceKey);
          setDisplayPrice("$15.00 (25% Discount)");
        }
      }
    }
  };
  useEffect(() => {
    getSettings();
  }, []);

  const item = {
    price: stripePrice,
    quantity: 1,
  };
  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`,
  };

  const redirectToCheckout = async () => {
    setLoading(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);

    setLoading(false);
  };

  const redirectToCancel = async () => {
    navigate("/cancel");
  };

  if (stripeError) alert(stripeError);

  return (
    <div className="form-container">
      <Helmet>
        <title>Checkout</title>
        <meta
          name="description"
          content="Approve your purchase of Global Entry Notifications system access."
        />
      </Helmet>
      <div className="location-preview">
        <h1>Checkout</h1>

        <br />
        <b>Step 2:</b>
        <br />
        <br />
        <span className="checkout-title">
          {" "}
          Global Entry Notifications
          <br />
          <br />
          Receive 35 days worth of Global Entry Appointment opening
          notifications.
        </span>
        <h1 className="checkout-price">{displayPrice}</h1>

        <img
          className="checkout-product-image"
          src={ProductImage}
          alt="Product"
          title="Global Entry Notification Purchase"
          width="300px"
          height="200px"
          loading="eager"
        />
        <br />
        <br />
        <br />

        <button
          className="checkout-button"
          onClick={redirectToCheckout}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Click here to Purchase"}
        </button>

        <br />
        <br />

        <br />
        <br />

        <button className="checkout-button" onClick={redirectToCancel}>
          Cancel
        </button>

        <br />
        <br />

        <p>&copy;GlobalEntryNotifications.com</p>
      </div>
    </div>
  );
};

export default Checkout;
